import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Layout from '../../components/layout'
import { Grid, Link, Typography } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import PaginationItem from '@material-ui/lab/PaginationItem'
import styles from './index.module.css'

class ListaArticoliTemplate extends React.Component {
  render() {
    const videoList = get(this.props, 'data.allContentfulVideo.nodes').map(
      (video) => video
    )
    const pageTitle = 'Movimento Rivoluzione Fiscale - Lista video'
    const slug = get(this.props, 'pageContext.slug')
    const total = get(this.props, 'pageContext.total')
    const maxPages = (total - 4 > 0 ? (total - 4) % 5 : 0) + 1
    const page = get(this.props, 'pageContext.page')
    const handleChange = (event, value) => {
      window.location.href = `/${slug}/${value}/`
    }

    return (
      <Layout location={this.props.location}>
        <Helmet title={pageTitle}>
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff" />
        </Helmet>
        <div className={styles.container}>
          <div className={styles.gridContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={styles.titleContainer}>
                <Typography variant={'h3'}>{pageTitle}</Typography>
              </Grid>
              {videoList.filter(a => a.titolo !== "NON ELIMINARE").map((video) => (
                <Grid item lg={4} spacing={2} key={`article-list-${video.id}`}>
                  <div
                    className={styles.iframeContainer}
                    dangerouslySetInnerHTML={{ __html: video.embed.embed }}
                  />
                  <Link href={`/video/${video.slug}/`}>
                    <Typography variant={'h3'} className={styles.titleArticle}>
                      {video.titolo}
                    </Typography>
                  </Link>
                  {video.autore && (
                    <Typography variant={'subtitle1'} className={styles.author}>
                      {`${video.autore.nome} ${video.autore.cognome}, ${video.dataPubblicazione}`}
                    </Typography>
                  )}
                </Grid>
              ))}
            </Grid>
            <Pagination
              classes={{
                root: styles.paginationRoot,
              }}
              renderItem={(item) => (
                <PaginationItem
                  classes={{
                    selected: styles.paginationItemSelected,
                  }}
                  {...item}
                />
              )}
              count={maxPages}
              page={page}
              onChange={handleChange}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </div>
      </Layout>
    )
  }
}

export default ListaArticoliTemplate

export const pageQuery = graphql`
  query VideoList {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulVideo(limit: 12) {
      nodes {
        id
        slug
        contenuto {
          childMarkdownRemark {
            html
          }
        }
        dataPubblicazione(formatString: "MMMM Do, YYYY")
        titolo
        autore {
          cognome
          nome
          descrizione
        }
        embed {
          embed
        }
      }
    }
  }
`
